import $http from '@/libs/axios'
import { saveAs } from 'file-saver'

class ExcelAPIs {
  static async getTemplate(type: string) {
    const file = (await $http.get(`/export/${type}`, { responseType: 'blob' })).data
    return saveAs(file, `${type}-template.xlsx`)
  }

  static async import(body: any, type: string) {
    const headers = { 'Content-Type': 'multipart/form-data' }
    return (await $http.post(`/import/${type}`, body, { headers })).data
  }
}

export default ExcelAPIs
