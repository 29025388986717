<template>
  <ok-text-input v-bind="$props">
    <v-select
      :value="value"
      :options="options"
      :disabled="readOnly || disabled"
      :reduce="branch => branch.id"
      label="name"
      :clearable="false"
      :multiple="multiple"
      class="branch-select"
      :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
      :placeholder="placeholder"
      :dir="isRTL ? 'rtl' : 'ltr'"
      autocomplete="off"
      @input="$emit('input', $event)"
    >
      <template v-slot:option="option">
        {{ option.ar_name }}
      </template>
      <template
        v-slot:selected-option="option"
      >
        {{ option.ar_name }}

      </template>
    </v-select>
    <slot name="note" />
  </ok-text-input>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import OkTextInput from './OkTextInput'
import okTextInputProps from './utils/ok-text-input-props'

export default {
  name: 'BranchSelect',
  components: {
    OkTextInput,
    vSelect,
  },
  props: {
    ...okTextInputProps,
    onlyBranches: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      branches: 'auth/branches',
    }),
    options() {
      if (!this.onlyBranches) return this.branches

      return this.branches.filter(branch => this.onlyBranches.includes(branch.id))
    },
  },
}
</script>

<style lang="scss">
.branch-select {
  .vs__dropdown-menu {
    max-height: 100px!important;
  }
}
</style>
