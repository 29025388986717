<template>
  <ok-modal
    :title="modalTitle"
    :value="value"
    size="md"
    @change="loading ? null : $emit('input', $event)"
  >
    <error-alert
      v-if="error"
      :error="error"
    />
    <div class="menu-pin-container">
      <div class="pr-1">
        {{ modalDescription }}
        <ul class="import-steps">
          <li>{{ $t('excel.step1') }}</li>
          <li v-if="type==='categories'">
            {{ $t('excel.category-image') }}
          </li>
          <li v-if="type==='items'">
            {{ $t('excel.item-images') }}
          </li>
          <li>{{ $t('excel.step2') }}</li>
          <li>{{ $t('excel.step3') }}</li>
        </ul>
        <p><span class="text-danger">{{ $t('general.note') }}:</span>{{ $t('excel.note') }}</p>
        <b-button
          :disabled="downloadingTemplate || downloading"
          block
          class="mt-2"
          size="md"
          variant="outline-secondary"
          @click="downloadTemplate"
        >
          <b-spinner
            v-if="downloadingTemplate || downloading"
            class="mr-50"
            small
          />
          {{ $t('excel.import-download-template') }}
        </b-button>
        <import-excel-input
          :type="type"
          class="mt-2"
          @error="error=$event"
          @imported="handleImport($event)"
        />
      </div>
    </div>

    <template #actions>
      <b-button
        :disabled="!file || loading || submitting"
        class="float-left mr-1"
        variant="dark"
        @click="submit"
      >
        <b-spinner
          v-if="loading"
          small
        />
        <span v-else>{{ $t('excel.import-start') }}</span>
      </b-button>
    </template>
  </ok-modal>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'
import ImportExcelInput from '@/views/store/inputs/ImportExcelInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import ExcelAPIs from '@/api/store/excel-api'
import OkModal from '@/@bya3/components/modals/OkModal'

export default {
  name: 'ExcelImportModal',
  components: {
    OkModal,
    ErrorAlert,
    ImportExcelInput,
    BButton,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'categories',
    },
    submitting: {
      type: Boolean,
      default: false,
    },
    downloading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      file: null,
      downloadingTemplate: false,
    }
  },
  computed: {
    modalTitle() {
      return this.$t(`excel.import-title-${this.type}`)
    },
    modalDescription() {
      return this.$t(`excel.import-description-${this.type}`)
    },
  },
  methods: {
    submit() {
      if (!this.file) return
      this.error = null
      this.loading = true
      ExcelAPIs.import(this.file, this.type)
        .then(response => {
          if (['warning', 'error'].includes(response.status)) {
            this.error = { message: response.message, title: this.$t('excel.import-failed') }
            return
          }
          this.$notifySuccess(this.$t('excel.excel-imported'))
          this.$emit('input', false)
        })
        .catch(e => {
          this.error = ErrorHandler.getErrorDetails(e)
        })
        .finally(() => {
          this.loading = false
        })
    },
    downloadTemplate() {
      this.downloadingTemplate = true
      ExcelAPIs.getTemplate(this.type).catch(err => this.$notifyError(err)).finally(() => {
        this.downloadingTemplate = false
      })
    },
    handleImport(form) {
      this.file = form
    },
  },
}
</script>

<style lang="scss">
.import-steps {
  list-style: decimal;
  padding: 0;
}

.import-steps li, p {
  color: #6d6a7a;
  font-size: 12px;
  font-weight: 500;
  margin: 5px 0 7px 10.5px;
}
</style>
