<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form">
      <div>
        <b-row class="align-items-center">
          <b-col
            md="6"
            class="mb-1"
          >
            <parent-category-select
              v-model="item.category_id"
              no-subcategories
              return-object
              size="md"
              :max-level="Infinity"
              required
              @category="category=$event"
            />
          </b-col>
          <b-col
            v-if="category && category.subcategories && category.subcategories.length"
            md="6"
            class="mb-1"
          >
            <parent-category-select
              v-model="item.subcategory_id"
              :parent-id="category.id"
              size="md"
              label="store.sub-category"
              :max-level="Infinity"
              required
            />
          </b-col>
          <b-col
            v-else
            md="6"
          />
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="name"
              size="md"
              :label="`${$t('store.item-name')} (${$t(`locales.${selectedLocale}`)})`"
              is-required
              textarea
            />
          </b-col>
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="item.description"
              size="md"
              :label="`${$t('store.item-description')} (${$t(`locales.${selectedLocale}`)})`"
              textarea
            />
          </b-col>
          <b-col md="4">
            <ok-text-input
              v-model="item.sort"
              is-required
              rules="positive"
              :label="$t('help.order')"
            />
          </b-col>
          <b-col
            v-if="showStock"
            md="12"
            lg="4"
            class="mb-1"
          >
            <item-stock-status-input v-model="item.available" />
          </b-col>
          <b-col
            v-if="showPreparationTime"
            md="6"
          >
            <ok-text-input
              :value="item.preparation_time"
              :label="$t('store.preparation-time')"
              :tooltip="$t('store.preparation-time-tooltip')"
              @input="item.preparation_time = $event"
            >
              <template #append>
                <b-form-select
                  v-model="item.preparation_time_unit"
                  :options="timeUnitOptions"
                />
              </template>
            </ok-text-input>
          </b-col>
          <b-col
            md="4"
            class="mb-1"
          >
            <branch-select
              v-model="item.branches"
              :label="$t('store.available-in-branch')"
              multiple
              :placeholder="$t('store.item-empty-branches')"
            />
          </b-col>
          <b-col md="12">
            <my-menu-media-input
              ref="media"
              v-model="item.media"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import { BRow, BCol, BFormSelect } from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationService from '@/services/localization-service'
import ItemStockStatusInput from '@/views/store/inputs/ItemStockStatusInput'
import ParentCategorySelect from '@/views/store/inputs/ParentCategorySelect'
import { BUSINESS_FEATURES } from '@/constants/business-types'
import BranchSelect from '@/@bya3/components/inputs/BranchSelect'

export default {
  name: 'ItemGeneralForm',
  components: {
    BranchSelect,
    MyMenuMediaInput: () => import(/* webpackChunkName: "cloud-input" */ '@/views/media/MyMenuMediaInput'),
    ItemStockStatusInput,
    OkTextInput,
    ParentCategorySelect,
    ValidationObserver,
    BRow,
    BCol,
    BFormSelect,
  },
  props: {
    form: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      loading: false,
      error: null,
      timeUnitOptions: [
        { value: 'minutes', text: this.$t('store.unit.minutes') },
        { value: 'hours', text: this.$t('store.unit.hours') },
        { value: 'days', text: this.$t('store.unit.days') },
      ],
      submitting: false,
      item: {},
      modified: false,
      modifiedReady: true,
      category: {},
      subcategory: {},
      name: '',
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params?.id
    },
    showPreparationTime() {
      // return this.$store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.PREPARATION_TIME)
      return false
    },
    showStock() {
      return this.$store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.STOCK)
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.item = { ...this.form }
      this.item.sort = this.item?.sort ?? 0
      this.name = LocalizationService.getTranslate(this.item, 'name', this.selectedLocale, true)
      this.item.description = LocalizationService.getTranslate(this.item, 'description', this.selectedLocale, true)
      setTimeout(() => { this.category = { ...this.item.category } }, 50)
    },
    setLocales() {
      this.item.locales = [{ locale: this.selectedLocale, name: this.name, description: this.item.description }]
    },
    prepareForSubmit() {
      this.setLocales()
      const keys = ['category_id', 'subcategory_id', 'status', 'name', 'sort', 'description', 'available', 'preparation_time', 'preparation_time_unit', 'branches']
      const values = pick(this.item, keys)

      const { locales } = this.item
      const media = this.$refs.media?.prepareForSubmit ? this.$refs.media.prepareForSubmit() : {}
      return {
        ...media,
        ...values,
        locales,
      }
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss">
.item-general-form {
  .parent-category-select {

    .vs__dropdown-toggle {
      max-height: 38px;

      .vs__selected {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: initial;
      }
    }
  }
}
</style>
