<template>
  <ok-text-input v-bind="$props">
    <b-form-select
      :value="value"
      :size="size"
      :disabled="disabled"
      @input="$emit('input', $event)"
    >
      <b-form-select-option
        v-for="(option, idx) in options"
        :key="idx"
        :value="option.value"
      >
        {{ $te(option.text || option.label) ? $t(option.text || option.label) : (option.text || option.label) }}
      </b-form-select-option>
    </b-form-select>
  </ok-text-input>
</template>

<script>
import { BFormSelect, BFormSelectOption } from 'bootstrap-vue'
import okTextInputProps from '@/@bya3/components/inputs/utils/ok-text-input-props'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'

export default {
  name: 'OkSelect',
  components: { OkTextInput, BFormSelect, BFormSelectOption },
  props: {
    ...okTextInputProps,
    options: {
      type: Array,
      default: () => ([]),
    },
  },
}
</script>
