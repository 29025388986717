<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-modifiers-form">
      <div class="item-modifiers-form-title">
        {{ $t('modifiers.group-title') }}
      </div>
      <item-modifiers-select
        ref="groups-select"
        v-model="modifiersList"
        :groups.sync="groups"
        class="mb-1"
        @add-group="addModifierVisible = true"
      />

      <div
        class="item-modifiers-form-list"
      >
        <b-row
          v-if="Object.keys(modifiers).length"
          class="d-none d-md-flex"
        >
          <b-col md="3">
            {{ $t('modifiers.group-name') }}
          </b-col>
          <b-col md="2">
            {{ $t('fields.required') }}
          </b-col>
          <b-col md="2">
            {{ $t('modifiers.select_type.label') }}
          </b-col>
          <b-col md="2">
            {{ $t('modifiers.min') }}
          </b-col>
          <b-col md="2">
            {{ $t('modifiers.max') }}
          </b-col>
        </b-row>

        <b-row
          v-for="groupId in Object.keys(modifiers)"
          :key="groupId"
          class="align-items-center"
        >
          <b-col
            md="3"
            cols="6"
            class="font-weight-semi-bold d-flex mb-1 mb-md-0"
          >
            <div class="d-block d-md-none pr-1">
              {{ $t('modifiers.modifiers-group') }}
            </div>
            {{ getGroupName(groupId) }}
          </b-col>
          <b-col
            md="2"
            cols="6"
            class="d-flex mb-1 mb-md-0"
          >
            <div class="d-block d-md-none pr-1">
              {{ $t('fields.required') }}
            </div>
            <ok-switch
              :value="!modifiers[groupId].optional"
              @input="modifiers[groupId].optional = !$event"
            />
          </b-col>
          <b-col
            md="2"
            cols="6"
            class="d-flex mb-1 mb-md-0"
          >
            <div class="d-block d-md-none pr-1">
              {{ $t('modifiers.select_type.label') }}
            </div>
            <ok-select
              v-model="modifiers[groupId].select_type"
              :options="selectType"
            />
          </b-col>

          <b-col
            md="2"
            cols="9"
            class="d-flex mb-1 mb-md-0"
          >
            <div class="d-block d-md-none pr-1 text-nowrap align-self-center">
              {{ $t('modifiers.min') }}
            </div>
            <ok-text-input
              v-model.number="modifiers[groupId].min"
              :placeholder="$t('modifiers.min')"
              :read-only="modifiers[groupId].select_type!==1"
              rules="positive"
            />
          </b-col>
          <b-col
            md="2"
            cols="6"
            class="d-flex mb-1 mb-md-0"
          >
            <div class="d-block d-md-none pr-1">
              {{ $t('modifiers.max') }}
            </div>
            <ok-text-input
              v-model="modifiers[groupId].max"
              :read-only="modifiers[groupId].select_type!==1"
              rules="positive"
              :placeholder="$t('modifiers.max')"
            />
          </b-col>
          <b-col
            md="1"
            cols="3"
          >
            <b-button
              variant="outline-danger"
              size="sm"
              class="btn-icon rounded-circle"
              @click="removeModifier(groupId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <process-group-modal
        v-model="addModifierVisible"
        @updated="handleCreateGroup"
      />
    </div>
  </validation-observer>
</template>

<script>
import {
  BCol, BRow, BButton,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import difference from 'lodash/difference'
import EditItemMixin from '@/views/store/mixins/edit-item-mixin'
import ItemBranchesMixin from '@/views/store/mixins/menu-mixin'
import LocalizationService from '@/services/localization-service'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import OkSwitch from '@/@bya3/components/inputs/OkSwitch'
import ItemModifiersSelect from '@/views/store/inputs/ItemModifiersSelect'
import OkSelect from '@/@bya3/components/inputs/OkSelect'
import ProcessGroupModal from '@/views/store/modifiers/ProcessGroupModal'

export default {
  name: 'ItemModifiersForm',
  components: {
    ProcessGroupModal,
    OkSelect,
    OkSwitch,
    OkTextInput,
    ItemModifiersSelect,
    ValidationObserver,
    BCol,
    BRow,
    BButton,
  },
  mixins: [EditItemMixin, ItemBranchesMixin],
  data() {
    return {
      modifiers: {},
      groups: [],
      addModifierVisible: false,
      addedModifier: false,
      showModifierModal: false,
      selectType: [
        { value: 0, text: this.$t('modifiers.select_type.single') },
        { value: 1, text: this.$t('modifiers.select_type.multiple') },
        { value: 2, text: this.$t('modifiers.select_type.single2') },
      ],
      countType: [
        { value: 0, text: this.$t('modifiers.count_type.by-item') },
        { value: 1, text: this.$t('modifiers.count_type.by-arrows') },
      ],
    }
  },
  computed: {
    ...mapGetters({
      isDark: 'appConfig/isDark',
    }),
    modifiersList: {
      get() { return Object.keys(this.modifiers).map(id => parseInt(id, 10)) },
      set(modifiersIds) {
        const modifiers = { ...this.modifiers }
        const removedIds = difference(this.modifiersList, modifiersIds)
        const insertedIds = difference(modifiersIds, this.modifiersList)
        removedIds.forEach(id => { delete modifiers[id] })
        insertedIds.forEach(id => {
          modifiers[id] = {
            min: 1, max: 5, select_type: 1, optional: true,
          }
        })
        this.modifiers = { ...modifiers }
      },
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.modifiers = (this.item.modifiers ?? []).reduce((prev, curr) => ({
        ...prev,
        [curr.id]: {
          min: curr.min, select_type: curr.select_type, max: curr.max, optional: curr.optional,
        },
      }), {})
    },
    prepareForSubmit() {
      return {
        modifiers: JSON.stringify(this.modifiers),
      }
    },
    getGroupName(groupId) {
      return LocalizationService.getTranslate(this.groups.find(group => group.id === parseInt(groupId, 10)) ?? {}, 'name')
    },
    removeModifier(groupId) {
      delete this.modifiers[groupId]
      this.modifiers = { ...this.modifiers }
    },
    handleCreateGroup(group) {
      this.$refs['groups-select'].addGroup(group)
      this.modifiersList = [...this.modifiersList, group.id]
      this.addModifierVisible = false

      this.addedModifier = group
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss">
.item-modifiers-form {
  .item-modifiers-form-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--purple)
  }

  .item-modifiers-form-description {
    font-size: 14px;
  }

  .item-modifiers-form-list {
    & > .row {
      margin-bottom: 10px;
      color: var(--purple);
    }
  }

  .modifier-groups-form {
    margin: 10px 0;
    padding: 20px;
    border: 1px solid var(--description-color);
    border-radius: 5px;
  }
}

</style>
