<template>
  <div>
    <div class="position-relative">
      <div ref="inputContainer">
        <multiselect
          :value="selectedItemsObjects"
          :options="itemsOptions"
          :multiple="multiple"
          track-by="id"
          selected-label="✅"
          deselect-label="❌"
          select-label="✔"
          :placeholder="$t('general.search-items')"
          :close-on-select="!multiple"
          :clear-on-select="false"
          :loading="loading"
          :preserve-search="true"
          :disabled="disabled"
          label="name"
          @select="handleMultiSelectSelect"
          @remove="handleMultiSelectRemove"
        >
          <template
            slot="selection"
            slot-scope="{ values, isOpen }"
          >
            <span
              v-if="values.length && !isOpen"
              class="multiselect__single bg-transparent"
            >{{ $t("general.items-selected",{num:values.length}) }}</span>
            <i v-else />
          </template>
          <template
            slot="option"
            slot-scope="{ option }"
          >
            {{ getName(option) }}
          </template>
          <template slot="beforeList">
            <b-row
              v-if="multiple && enableSelectAll"
              class="no-gutters"
            >
              <b-col>
                <b-button
                  squared
                  block
                  variant="outline-success"
                  @click="selectAll()"
                >
                  {{ $t("forms.select-all") }}
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  squared
                  block
                  variant="outline-danger"
                  @click="deselectAll()"
                >
                  {{ $t("forms.deselect-all") }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import uniqBy from 'lodash/uniqBy'
import ItemAPIs from '@/api/store/item'
import LocalizationService from '@/services/localization-service'

import(/* webpackChunkName: "multiselect" */ 'vue-multiselect/dist/vue-multiselect.min.css')

export default {
  name: 'ItemSelect',
  components: {
    Multiselect,
  },
  props: {
    all: {
      type: Boolean,
      default: true,
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    disableInitialEmit: {
      type: Boolean,
      default: false,
    },
    except: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    enableSelectAll: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    fireObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
      itemsIds: [],
      selectedItem: [],
      loading: false,
    }
  },
  computed: {
    itemsOptions() {
      return this.items.filter(c => !this.except.includes(c.id))
    },
    selectedItemsObjects() {
      if (this.multiple) {
        return this.items.filter(c => this.selectedItem.includes(c.id))
      }
      return this.items.find(c => this.selectedItem === c.id)
    },
  },
  watch: {
    value(value) {
      this.selectedItem = value
    },
    selectedItem(itemId) {
      this.$emit('input', itemId)
      if (this.fireObject) this.$emit('items', this.selectedItemsObjects)
    },
  },
  mounted() {
    if (this.value) this.selectedItem = this.value
    else {
      this.selectedItem = this.multiple ? [] : ''
    }
    if (this.selected.length) this.items = this.selected
    this.fetchItems('', !this.disableInitialEmit)
  },
  methods: {
    getName(option) {
      return LocalizationService.getTranslate(option, 'name', this.selectedLocale)
    },
    fetchItems(search, initWithValue = false, resetItems = false) {
      this.loading = true
      ItemAPIs.search(search)
        .then(items => {
          if (resetItems) {
            this.items = []
          }
          this.items = uniqBy(this.items.concat(items), i => i.id)
          this.$emit('itemsListChanged', this.items)
          this.itemsIds = this.items.map(c => c.id)
          if (initWithValue) {
            this.selectedItem = this.multiple && !Array.isArray(this.value) ? [this.value] : this.value
            if (this.multiple) {
              this.selectedItem = this.selectedItem.filter(itemId => this.itemsIds.includes(itemId))
            } else if (!this.itemsIds.includes(this.selectedItem)) {
              this.selectedItem = null
            }
          }
        })
        .catch(err => {
          this.$notifyError(err)
        })
        .finally(() => { this.loading = false })
    },
    selectAll() {
      const selected = []
      this.items.map(cat => selected.push(cat.id))
      this.selectedItem = selected
    },
    deselectAll() {
      this.selectedItem = []
    },
    handleMultiSelectSelect(item) {
      if (this.multiple) {
        this.selectedItem.push(item.id)
      } else this.selectedItem = item.id
    },
    handleMultiSelectRemove(item) {
      if (this.multiple) {
        this.selectedItem = this.selectedItem.filter(c => c !== item.id)
      } else this.selectedItem = item.id
    },
  },
}
</script>
