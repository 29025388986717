import cloneDeep from 'lodash/cloneDeep'

const EditItemMixin = {
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      item: cloneDeep(this.value),
    }
  },
  watch: {
    item: {
      deep: true,
      handler(val) {
        this.$emit('input', val)
      },
    },
    value(value) {
      this.item = value
    },
  },
}

export default EditItemMixin
