<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div class="item-general-form">
      <div>
        <b-row class="align-items-center">
          <b-col
            md="6"
            class="mb-1"
          >
            <ok-text-input
              v-model="item.name"
              size="md"
              :label="`${$t('modifiers.group-name')} (${$t(`locales.${selectedLocale}`)})`"
              is-required
            />
          </b-col>
          <b-col
            md="2"
            class="mb-1"
          >
            <b-form-checkbox
              v-model="item.status"
              :checked="item.status===1"
              :value="1"
              :unchecked-value="0"
              switch
            >
              {{ $t('general.status') }}
            </b-form-checkbox>
          </b-col>
        </b-row>
      </div>

      <b-row>
        <b-col md="12">
          <ok-text-input :label="$t('modifiers.modifier-title')">
            <modifiers-select
              v-model="item.modifiers"
              multiple
            />
          </ok-text-input>
        </b-col>
      </b-row>
      <template v-if="item.modifiers.length">
        <b-table
          striped
          bordered
          borderless
          :items="modifiers"
          :fields="fields"
        >
          <template #cell(name)="data">
            {{ getModifierName(data) }}
          </template>
        </b-table>
      </template>

    </div>
  </validation-observer>
</template>

<script>
import {
  BRow, BCol, BTable, BFormCheckbox,
} from 'bootstrap-vue'
import pick from 'lodash/pick'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import LocalizationService from '@/services/localization-service'
import ModifiersSelect from '@/views/store/modifiers/components/ModifiersSelect'
import EditItemMixin from '../../mixins/edit-group-mixin'

export default {
  name: 'GroupGeneralForm',
  components: {
    ModifiersSelect,
    BFormCheckbox,
    OkTextInput,
    ValidationObserver,
    BRow,
    BCol,
    BTable,
  },
  mixins: [EditItemMixin],
  data() {
    return {
      loading: false,
      error: null,
      submitting: false,
      modified: false,
      modifiedReady: true,
      fields: [
        { key: 'name', label: this.$t('fields.name') },
        { key: 'price', label: this.$t('store.prices.price') },
      ],
    }
  },
  computed: {
    editMode() {
      return !!this.$route.params?.id
    },
    modifiers() {
      const storeModifiers = this.$store.getters['modifier/modifiers']
      return this.item.modifiers.map(id => storeModifiers.find(el => el.id === id))
    },
  },
  watch: {
    active(active) {
      if (active) {
        this.init()
      }
    },
  },
  methods: {
    setAndInit() {
      this.setLocales()
      this.init()
    },
    getModifierName(data) {
      return LocalizationService.getTranslate(data.item, 'name', this.selectedLocale)
    },
    init() {
      this.item.name = LocalizationService.getTranslate(this.item, 'name', this.selectedLocale, true)
    },
    setLocales() {
      this.item.locales = [{ locale: this.selectedLocale, name: this.item.name }]
    },
    prepareForSubmit() {
      this.setLocales()

      const keys = ['price', 'status', 'name', 'modifiers']
      const values = pick(this.item, keys)
      const { locales } = this.item
      return {
        ...values,
        locales,
      }
    },
    async isValid() {
      return this.$refs.form.validate()
    },
  },
}
</script>

<style lang="scss">
.item-general-form {
  .parent-category-select {

    .vs__dropdown-toggle {
      max-height: 38px;

      .vs__selected {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* number of lines to show */
        line-clamp: 1;
        -webkit-box-orient: vertical;
        white-space: initial;
      }
    }
  }
}
</style>
