import locales from '@/constants/locales'

const MenuMixin = {
  methods: {
    itemLocales() {
      return [...new Set(locales.filter(locale => this.userLocales.includes(locale.id) || locale.id === this.$i18n.locale).map(locale => locale.id))]
    },
    hasMultiLanguages() {
      return this.itemLocales.length > 1
    },
  },
}

export default MenuMixin
