const ALL_LOCALES = [
  { id: 'en', name: 'English', flag: 'flag-US' },
  {
    id: 'ar', name: 'العربية', direction: 'rtl', flag: 'flag-KW',
  },
  { id: 'ku', name: 'Kurdî', flag: 'flag-IR' },
  { id: 'ru', name: 'русский', flag: 'flag-RU' },
  { id: 'fr', name: 'Français', flag: 'flag-FR' },
  { id: 'tr', name: 'Türkçe', flag: 'flag-TR' },
]

export default ALL_LOCALES
