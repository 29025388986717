<template>
  <div>
    <fetching-data-spinner
      :error="fetchError"
      :loading="fetch"
      :loading-title="$t('store.loading-item')"
      enable-retry
      @retry="init"
    />
    <div v-if="!fetch">
      <validation-observer ref="form">

        <empty-card
          :title="$t(`store.${isEdit?'edit-product':'add-product'}`)"
        >
          <template slot="title">
            <div
              class="d-flex align-items-center"
            >
              <b-button
                variant="link"
                :disabled="submitting"
                @click="$router.push({name:'owner:store-products'})"
              >
                <span class="text-dark">{{ $t('forms.back') }}</span>
              </b-button>
              <b-button
                variant="dark"
                :disabled="submitting"
                @click="submit()"
              >
                <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
                <b-spinner
                  v-if="submitting"
                  small
                />
              </b-button>
            </div>
          </template>
          <error-alert
            v-if="error"
            class="mt-1"
            :error="error"
          />
          <b-tabs
            v-model="tab"
            :class="error?'mt-1':'mt-3'"
            justified
            pills
          >
            <b-tab
              :title="$t('store.general')"
              :title-link-class="isGeneralValid ? '' : 'bg-danger text-white'"
            >
              <item-general-form
                ref="general"
                :form="form"
                :active="tab === 0"
              />
            </b-tab>
            <b-tab
              title="الاسعار"
              :title-link-class="isPricesValid ? '' : 'bg-danger text-white'"
            >
              <item-prices-form
                ref="prices"
                :price-labels="form.prices"
                :active="tab === 1"
              />
            </b-tab>
            <b-tab
              v-if="showModifiersTap"
              title="الاضافات"
              :title-link-class="isModifiersValid ? '' : 'bg-danger text-white'"
            >
              <item-modifiers-form
                ref="modifiers"
                v-model="form"
                :active="tab === 2"
              />
            </b-tab>
            <b-tab
              v-if="userLocalesWithoutCurrent.length>0"
              title="اللغات"
            >
              <item-localization-form
                ref="languages"
                :item="form"
                :active="tab === (showModifiersTap ? 3 : 2)"
              />
            </b-tab>

          </b-tabs>
        </empty-card>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BButton, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import ItemAPIs from '@/api/store/item'
import FetchingDataSpinner from '@/@bya3/components/utils/FetchingDataSpinner'
import ItemGeneralForm from '@/views/store/products/components/ItemGeneralForm'
import ItemPricesForm from '@/views/store/products/components/ItemPricesForm'
import EmptyCard from '@/@bya3/components/cards/EmptyCard'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import LocalizationService from '@/services/localization-service'
import ItemLocalizationForm from '@/views/store/products/components/ItemLocalizationForm'
import ItemModifiersForm from '@/views/store/products/components/ItemModifiersForm'
import { BUSINESS_FEATURES } from '@/constants/business-types'

export default {
  name: 'ProcessItemPage',
  components: {
    ItemModifiersForm,
    ItemLocalizationForm,
    EmptyCard,
    ErrorAlert,
    ItemPricesForm,
    ItemGeneralForm,
    FetchingDataSpinner,
    ValidationObserver,
    BButton,
    BTabs,
    BTab,
    BSpinner,
  },
  data() {
    return {
      loading: false,
      fetch: true,
      fetchError: null,
      modified: false,
      modifiedReady: true,
      error: null,
      form: {
        preparation_time_unit: 'minutes',
        status: 1,
      },
      submitting: false,
      tab: 0,
      isGeneralValid: true,
      isDetailsValid: true,
      isModifiersValid: true,
      isPricesValid: true,
    }
  },
  computed: {
    isEdit() {
      return !!this.$route.params?.id
    },
    showModifiersTap() {
      return this.$store.getters['businessTypes/isEnabled'](BUSINESS_FEATURES.MODIFIERS)
    },
  },
  watch: {
    value(visible) {
      this.error = null
      this.tab = 0
      if (visible) {
        setTimeout(() => { this.modifiedReady = true; this.modified = false }, 1000)
      }
    },
    form: {
      deep: true,
      handler() {
        if (this.modifiedReady) {
          this.modified = true
        }
      },
    },
  },
  async mounted() {
    await this.fetchCategories()
    await this.fetchDependencies()
    await this.init()
  },
  methods: {
    getTitle() {
      if (this.isEdit) return this.$t('store.edit-item')
      return this.$t('store.add-item')
    },
    async init() {
      if (!this.isEdit) {
        this.fetch = false
        return
      }
      const { id } = this.$route.params
      this.fetch = true
      this.fetchError = null
      this.modifiedReady = false
      this.modified = false
      await ItemAPIs.getById(id).then(async res => {
        const form = res?.item ? cloneDeep(res.item) : {}
        form.is_category = false
        form.media = [...form.images]
        form.description = LocalizationService.getTranslate(form, 'description', this.selectedLocale, true)
        form.name = LocalizationService.getTranslate(form, 'name', this.selectedLocale, true)
        form.title = LocalizationService.getTranslate(form, 'name', this.selectedLocale)
        form.base_description = LocalizationService.getTranslate(form, 'description', this.selectedLocale)
        this.form = form
        // eslint-disable-next-line no-multi-assign
        setTimeout(() => { this.modifiedReady = true; this.modified = false }, 1000)
      })
        .catch(e => { this.error = ErrorHandler.getErrorDetails(e) })
        .finally(() => { this.fetch = false })
    },
    async submit() {
      this.error = null
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.isGeneralValid = await this.$refs.general.isValid()
        this.isPricesValid = await this.$refs.prices.isValid()
        if (this.showModifiersTap) this.isModifiersValid = await this.$refs.modifiers.isValid()

        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }

      // eslint-disable-next-line no-multi-assign
      this.isGeneralValid = true
      this.isModifiersValid = true
      this.isPricesValid = true
      const price_labels = this.$refs.prices.prepareForSubmit()
      const modifiers = (this.showModifiersTap) ? this.$refs.modifiers.prepareForSubmit() : {}
      const general = this.$refs.general.prepareForSubmit()
      let locales = [...general.locales]
      if (this.userLocalesWithoutCurrent.length > 0) locales = [...locales, ...this.$refs.languages.prepareForSubmit()]
      general.locales = JSON.stringify(locales)
      const payload = {
        ...general,
        ...price_labels,
        ...modifiers,
      }
      if (this.isEdit) payload.id = this.$route.params.id
      this.submitting = true
      ItemAPIs.process(payload)
        .then(async response => {
          if (response?.response === 419) {
            this.$notifyError(response.message)
            this.error = response.message
          } else {
            this.modified = false
            this.$router.push({ name: 'owner:store-products' })
          }
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
    handleClose() {
      this.$emit('input', false)
    },
    async fetchCategories() {
      await this.$store.dispatch('shop/FETCH_CATEGORIES')
        .catch(e => { console.log(e) })
    },
    async fetchDependencies() {
      await this.$store.dispatch('shop/FETCH_ITEM_DEPENDENCIES')
        .catch(e => { console.log(e) })
    },
  },
}
</script>
<style lang="scss">

.nav{
  &.nav-pills.nav-justified .nav-link{
    border-radius: 0;
  }
  &.nav-pills.nav-justified .nav-link:not(.active){
    background-color: #D8D6DD;
    border-left: 1px solid #ffffff;
  }
}
</style>
