<template>
  <ok-modal
    :value="value"
    size="lg"
    dialog-class="groups-modal"
    :title="$t('forms.'+(isEdit?'edit':'add'))"
    @change="$emit('input',false)"
  >
    <validation-observer ref="form">
      <b-tabs
        v-model="tab"
        class="mt-3"
        justified
        pills
      >
        <b-tab
          :title="$t('store.general')"
          :title-link-class="isGeneralValid ? '' : 'bg-danger text-white'"
        >
          <group-general-form
            ref="general"
            v-model="form"
            :active="tab === 0"
          />
        </b-tab>
        <b-tab
          v-if="userLocalesWithoutCurrent.length>0"
          title="اللغات"
        >
          <modifier-localization-form
            ref="languages"
            v-model="form"
            :active="tab === 1"
          />
        </b-tab>

      </b-tabs>
    </validation-observer>
    <template #actions>
      <div
        class="d-flex align-items-center"
      >
        <b-button
          variant="link"
          :disabled="submitting"
          @click="$emit('input',false)"
        >
          <span class="text-dark">{{ $t('forms.back') }}</span>
        </b-button>
        <b-button
          variant="dark"
          :disabled="submitting"
          @click="submit()"
        >
          <span :class="{'pr-1': submitting}">{{ $t('forms.save') }}</span>
          <b-spinner
            v-if="submitting"
            small
          />
        </b-button>
      </div>
    </template>
  </ok-modal>
</template>

<script>
import {
  BButton, BTabs, BTab, BSpinner,
} from 'bootstrap-vue'
import cloneDeep from 'lodash/cloneDeep'
import { ValidationObserver } from 'vee-validate'
import ErrorHandler from '@/utils/error-handler'
import LocalizationService from '@/services/localization-service'
import ModifierAPIs from '@/api/store/modifier'
import ModifierLocalizationForm from '@/views/store/modifiers/components/ModifierLocalizationForm'
import GroupGeneralForm from '@/views/store/modifiers/components/Group/GroupGeneralForm'
import OkModal from '@/@bya3/components/modals/OkModal'

export default {
  name: 'ProcessGroupModal',
  components: {
    OkModal,
    GroupGeneralForm,
    ModifierLocalizationForm,
    ValidationObserver,
    BButton,
    BTabs,
    BTab,
    BSpinner,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    group: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      form: {
        status: true,
        modifiers: [],
      },
      submitting: false,
      tab: 0,
      isGeneralValid: true,
      isPricesValid: true,
    }
  },
  computed: {
    isEdit() {
      return !!this.group?.id
    },
  },
  watch: {
    value() {
      this.error = null
      this.tab = 0
    },
    group() {
      this.init()
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    async init() {
      if (!this.isEdit) {
        this.form = {
          status: 1,
          modifiers: [],
        }
        return
      }
      const form = cloneDeep(this.group)
      form.name = LocalizationService.getTranslate(form, 'name', this.selectedLocale, true)
      form.title = LocalizationService.getTranslate(form, 'name', this.selectedLocale)
      this.form = form
    },
    async submit() {
      await new Promise(resolve => setTimeout(resolve, 200))
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.isGeneralValid = await this.$refs.general.isValid()
        this.isPricesValid = await this.$refs.prices.isValid()

        this.error = { title: this.$tc('errors.field-invalid', 2), message: ErrorHandler.getValidationErrors(valid.errors) }
        return
      }
      if (!this.form?.modifiers?.length) {
        this.isGeneralValid = false
        this.$notifyError(this.$t('errors.choose-one-modifier'))
        return
      }
      // eslint-disable-next-line no-multi-assign
      this.isGeneralValid = true
      const general = this.$refs.general.prepareForSubmit()
      let locales = [...general.locales]
      if (this.userLocalesWithoutCurrent.length > 0) locales = [...locales, ...this.$refs.languages.prepareForSubmit()]
      general.locales = JSON.stringify(locales)
      const payload = {
        ...general,
      }
      if (this.isEdit) payload.id = this.group.id
      this.submitting = true
      ModifierAPIs.processGroup(payload)
        .then(async response => {
          this.$notifySuccess(response.message)
          this.$emit('updated', response.group)
          this.$emit('input', false)
        })
        .catch(error => { this.error = error })
        .finally(() => { this.submitting = false })
    },
    handleClose() {
      this.$emit('input', false)
    },
  },
}
</script>
