<template>
  <validation-provider
    #default="{ errors }"
    :name="$t(label)"
    :debounce="200"
    :rules="required ? 'required' : ''"
  >
    <b-form-group
      :label="$t(label)"
      :label-class="{required: required}"
      :state="errors.length ? false : null"
      class="parent-category-select"
      :label-cols-lg="horizontal ? 4 : 12"
    >
      <v-select
        ref="select"
        :value="value"
        :placeholder="$t(placeholder)"
        :options="paginated"
        :filterable="false"
        label="name"
        :dir="isRTL ? 'rtl' : 'ltr'"
        :class="`select-size-${size}`"
        :reduce="r => r.id"
        :clearable="clearable"
        max-height="150px"
        @search="onSearch"
        @input="fireInput($event)"
      >
        <template #option="{ name, parent_name, id }">
          <template v-if="parent_name">
            {{ parent_name }} /
          </template>
          <span class="font-weight-bold">{{ name }}</span>
          <template v-if="!id">
            <feather-icon
              icon="StarIcon"
              size="13"
            />
          </template>
        </template>
        <template #list-footer>
          <div
            v-if="false"
            class="d-flex justify-content-between align-items-center pt-1"
          >
            <b-button
              variant="outline-secondary"
              block
              squared
              class="m-0"
              :disabled="!hasPrevPage"
              @click="offset -= limit"
            >
              {{ $t('forms.prev') }}
            </b-button>
            <b-button
              variant="secondary"
              block
              squared
              class="m-0"
              :disabled="!hasNextPage"
              @click="offset += limit"
            >
              {{ $t('forms.next') }}
            </b-button>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
      <p v-if="showHint">
        {{ $t('store.select-parent-category-hint') }}
      </p>
    </b-form-group>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { BButton, BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import '@validations'

export default {
  name: 'ParentCategorySelect',
  components: {
    vSelect,
    BButton,
    BFormGroup,
    ValidationProvider,
  },
  props: {
    value: {
      type: Number,
      default: null,
    },
    required: {
      type: Boolean,
      default: true,
    },
    showHint: {
      type: Boolean,
      default: false,
    },
    noItems: {
      type: Boolean,
      default: false,
    },
    noSubcategories: {
      type: Boolean,
      default: false,
    },
    allowRoot: {
      type: Boolean,
      default: false,
    },
    excludedId: {
      type: Number,
      default: null,
    },
    maxLevel: {
      type: Number,
      default: 3,
    },
    horizontal: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'store.parent-category',
    },
    placeholder: {
      type: String,
      default: 'forms.please-choose',
    },
    allowAddWhenEmpty: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    parentId: {
      type: Number,
      default: -1,
    },
  },
  data: () => ({
    search: '',
    offset: 0,
    limit: Infinity,
  }),
  computed: {
    categories() {
      let categories = this.$store.getters['shop/flatCategories']
      if (this.parentId > -1) {
        categories = categories.filter(category => category.parent_id === this.parentId)
      }
      if (this.noItems) {
        categories = categories.filter(category => !category.items_count || category?.subcategories?.length)
      }
      if (this.noSubcategories) {
        categories = categories.filter(category => !category.parent_id)
      }
      if (this.allowRoot) {
        const rootCategory = { name: this.$t('my-menu.main-category'), id: 0, level: 0 }
        categories = [rootCategory, ...categories]
      }
      return categories.filter(category => category.level <= this.maxLevel)
    },
    filtered() {
      let filtered = this.categories
      if (this.search) {
        filtered = this.categories.filter(category => (`${category.parent_name || ''} / ${category.name}`).toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
      }
      if (this.excludedId >= 0) {
        filtered = filtered.filter(category => category.id !== this.excludedId)
      }
      return filtered
    },
    paginated() {
      return this.filtered.slice(this.offset, this.limit + this.offset)
    },
    hasNextPage() {
      const nextOffset = this.offset + this.limit
      return Boolean(
        this.filtered.slice(nextOffset, this.limit + nextOffset).length,
      )
    },
    hasPrevPage() {
      const prevOffset = this.offset - this.limit
      return Boolean(
        this.filtered.slice(prevOffset, this.limit + prevOffset).length,
      )
    },
  },
  mounted() {
    setTimeout(() => {
      if (this.value) this.fireInput(this.value)
    }, 100)
  },
  methods: {
    onSearch(query) {
      this.search = query
      this.offset = 0
    },
    fireInput(id) {
      this.$emit('input', id)
      if (this.returnObject) {
        this.$emit('category', this.categories.find(cat => cat.id.toString() === id.toString()))
      }
    },
  },
}
</script>

<style lang="scss">
.parent-category-select {
  .form-row {
    align-items: center;
  }

  .vs__dropdown-menu {
    max-height: 150px!important;
  }
}
</style>
