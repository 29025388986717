<template>
  <div>
    <v-select
      :value="value"
      :options="modifiers"
      :disabled="readonly"
      :multiple="multiple"
      :reduce="modifier => modifier.id"
      :loading="loading"
      dir="rtl"
      :clearable="multiple || clearable"
      :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm', 'is-invalid': state === false}"
      autocomplete="off"
      @input="$emit('input', $event)"
    >
      <template
        slot="option"
        slot-scope="option"
      >
        <div
          class="d-center"
        >
          {{ getName(option) }}
        </div>
      </template>
      <template
        slot="selected-option"
        slot-scope="option"
      >
        {{ getName(option) }}
      </template>
    </v-select>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { mapGetters } from 'vuex'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'ModifiersSelect',
  components: {
    vSelect,
  },
  props: {
    value: {
      type: [String, Number, Array],
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      modifiers: 'modifier/modifiers',
    }),
  },
  methods: {
    getName(modifier) {
      return LocalizationService.getTranslate(modifier, 'name', this.selectedLocale)
    },
  },
}
</script>
