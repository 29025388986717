<template>
  <div>
    <ok-table
      ref="datatable"
      :title="$t('store.products')"
      :config="config"
      @edit="openModal"
      @delete="handleDelete"
    >
      <template slot="buttons">
        <b-button
          size="md"
          variant="dark"
          @click="$router.push({name:'owner:store-product'})"
        >
          {{ $t('store.add-product') }}
        </b-button>
        <b-button
          size="md"
          variant="outline-warning"
          class="ml-md-1"
          @click="showExcel=true"
        >
          {{ $t('excel.import') }}
        </b-button>
        <b-button
          size="md"
          class="mx-md-1"
          variant="outline-dark"
          @click="showQuickEditPrice = true"
        >
          {{ $t('price.quick-edit-price') }}
        </b-button>

        <b-button
          v-if="showRevert"
          size="md"
          class="mx-md-1"
          variant="success"
          @click="revertBulk"
        >
          {{ $t('price.revert-edit-price') }}
        </b-button>
      </template>
      <template
        slot="title-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="primary"
        >
          {{ getTitle(props.row) }}
        </b-badge>

      </template>
      <template
        slot="category-column"
        slot-scope="{props}"
      >
        <b-badge
          variant="info"
        >
          {{ getTitle(props.row.category) }}
        </b-badge>

      </template>
      <template
        v-slot:status-column="{props}"
      >
        <b-form-checkbox
          switch
          :unchecked-value="0"
          :checked="props.row.status"
          :value="1"
          @input="updateStatus($event,'status',props.row.id)"
        />

      </template>
    </ok-table>
    <quick-edit-price-form
      v-model="showQuickEditPrice"
      @updated="refreshTable(true)"
    />
    <excel-import-modal
      v-model="showExcel"
      type="items"
    />
  </div>

</template>

<script>
import { BButton, BBadge, BFormCheckbox } from 'bootstrap-vue'
import OkTable from '@/@bya3/components/tables/ok-table/OkTable'
import ProductPIs from '@/api/store/item'
import LocalizationService from '@/services/localization-service'
import QuickEditPriceForm from '@/views/store/price-labels/components/QuickEditPriceForm'
import PriceLabelsAPIs from '@/api/store/price-label'
import ExcelImportModal from '@/views/store/ExcelImportModal'
import ProductsTableConfig from './config/products-table-config'

export default {
  components: {
    ExcelImportModal,
    QuickEditPriceForm,
    OkTable,
    BButton,
    BBadge,
    BFormCheckbox,
  },
  data() {
    return {
      config: ProductsTableConfig,
      showItemModal: false,
      selectedItem: {},
      showQuickEditPrice: false,
      showRevert: false,
      showExcel: false,
    }
  },
  methods: {
    getTitle(item) {
      return LocalizationService.getTranslate(item)
    },
    refreshTable(showRevert = false) {
      this.showItemModal = false
      this.selectedItem = {}
      this.$refs.datatable.loadItems()
      if (showRevert) this.showRevert = true
    },
    openModal(data) {
      this.$router.push({ name: 'owner:store-product', params: { id: data.row.id } })
    },
    handleDelete(data) {
      this.$okConfirm(this.$t('general.delete-confirm'), {
        preConfirm: () => ProductPIs.delete(data.row.id)
          .then(response => {
            this.$notifySuccess(response.message)
            this.refreshTable()
          })
          .catch(e => this.$notifyError(e)),
      })
    },
    updateStatus(value, column, id) {
      const data = { id }
      data[column] = value
      ProductPIs.process(data)
        .then(response => {
          this.$notifySuccess(response.message)
          this.refreshTable()
        })
        .catch(e => this.$notifyError(e))
    },
    revertBulk() {
      this.$okConfirm(this.$t('price.revert-edit-confirm'), {
        confirmButtonText: this.$t('forms.submit'),
        showCancel: true,
        preConfirm: () => PriceLabelsAPIs.revertEditBulk()
          .then(response => {
            if (response.error === 0) {
              this.$notifySuccess(response.message)
              this.refreshTable()
              this.showRevert = false
            } else {
              this.$notifyError(response.message)
            }
          })
          .catch(e => this.$notifyError(e)),
      })
    },
  },

}
</script>
