import $http from '@/libs/axios'

class PriceLabelsAPIs {
  static async delete(id: number) {
    return (await $http.delete(`price-labels/${id}`)).data
  }

  static async list() {
    return (await $http.get('price-labels')).data
  }

  static async process(data: any) {
    return (await $http.post('price-labels', data)).data
  }

  static async editBulk(data: any) {
    return (await $http.post('prices/edit-bulk', data)).data
  }

  static async revertEditBulk() {
    return (await $http.get('prices/revert-edit-bulk')).data
  }
}

export default PriceLabelsAPIs
