<template>
  <div>
    <div class="position-relative">
      <div ref="inputContainer">
        <multiselect
          :value="selectedCategoryObjects"
          :options="categoriesOptions"
          :multiple="multiple"
          track-by="id"
          selected-label="✅"
          deselect-label="❌"
          select-label="✔"
          :placeholder="$t('general.search-items')"
          :close-on-select="!multiple"
          :clear-on-select="false"
          :loading="loading"
          :preserve-search="true"
          label="name"
          @select="handleMultiSelectSelect"
          @remove="handleMultiSelectRemove"
        >
          <template
            slot="selection"
            slot-scope="{ values, isOpen }"
          >
            <span
              v-if="values.length && !isOpen"
              class="multiselect__single bg-transparent"
            >{{ $t("general.items-selected",{num:values.length}) }}</span>
            <i v-else />
          </template>
          <template
            slot="option"
            slot-scope="{ option }"
          >
            {{ getName(option) }}
          </template>
          <template slot="beforeList">
            <b-row
              v-if="multiple && enableSelectAll"
              class="no-gutters"
            >
              <b-col>
                <b-button
                  squared
                  block
                  variant="outline-success"
                  @click="selectAll()"
                >
                  {{ $t("forms.select-all") }}
                </b-button>
              </b-col>
              <b-col>
                <b-button
                  squared
                  block
                  variant="outline-danger"
                  @click="deselectAll()"
                >
                  {{ $t("forms.deselect-all") }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </multiselect>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import uniqBy from 'lodash/uniqBy'
import CategoryAPIs from '@/api/store/category'
import LocalizationService from '@/services/localization-service'

import(/* webpackChunkName: "multiselect" */ 'vue-multiselect/dist/vue-multiselect.min.css')

export default {
  name: 'CategorySelect',
  components: {
    Multiselect,
  },
  props: {
    all: {
      type: Boolean,
      default: true,
    },
    hasSubcategories: {
      type: Boolean,
      default: false,
    },
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    disableInitialEmit: {
      type: Boolean,
      default: false,
    },
    except: {
      type: Array,
      default: () => [],
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    enableSelectAll: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    fireObject: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      categories: this.hasSubcategories ? [{ name: this.$t('menu.categories.root-category'), id: null, root: true }] : [],
      categoriesIds: this.hasSubcategories ? [null] : [],
      selectedCategory: [],
      loading: false,
    }
  },
  computed: {
    fetchingType() {
      if (this.all) return null
      return this.hasSubcategories ? 'category' : 'item'
    },
    categoriesOptions() {
      return this.categories.filter(c => !this.except.includes(c.id))
    },
    selectedCategoryObjects() {
      if (this.multiple) {
        return this.categories.filter(c => this.selectedCategory.includes(c.id))
      }
      return this.categories.find(c => this.selectedCategory === c.id)
    },
  },
  watch: {
    value(value) {
      this.selectedCategory = value
    },
    selectedCategory(categoryId) {
      this.$emit('input', categoryId)
      if (this.fireObject) this.$emit('categories', this.selectedCategoryObjects)
    },
  },
  mounted() {
    if (this.value) this.selectedCategory = this.value
    else {
      this.selectedCategory = this.multiple ? [] : ''
    }
    this.fetchCategories('', !this.disableInitialEmit)
    if (this.selected.length) this.categories = this.selected
  },
  methods: {
    getName(option) {
      return LocalizationService.getTranslate(option, 'name', this.selectedLocale)
    },
    fetchCategories(search, initWithValue, resetCategories = false) {
      this.loading = true
      CategoryAPIs.search(search)
        .then(categories => {
          if (resetCategories) {
            this.categories = this.hasSubcategories ? [{ name: this.$t('menu.categories.root-category'), id: null, root: true }] : []
          }
          this.categories = uniqBy(this.categories.concat(categories), i => i.id)
          this.categoriesIds = this.categories.map(c => c.id)
          if (initWithValue) {
            this.selectedCategory = this.multiple && !Array.isArray(this.value) ? [this.value] : this.value
            if (this.multiple) {
              this.selectedCategory = this.selectedCategory.filter(categoryId => this.categoriesIds.includes(categoryId))
            } else if (!this.categoriesIds.includes(this.selectedCategory)) {
              this.selectedCategory = null
            }
          }
        })
        .catch(err => {
          this.$notifyError(err)
        })
        .finally(() => { this.loading = false })
    },
    selectAll() {
      const selected = []
      this.categories.map(cat => selected.push(cat.id))
      this.selectedCategory = selected
    },
    deselectAll() {
      this.selectedCategory = []
    },
    handleMultiSelectSelect(category) {
      if (this.multiple) {
        this.selectedCategory.push(category.id)
      } else this.selectedCategory = category.id
    },
    handleMultiSelectRemove(category) {
      if (this.multiple) {
        this.selectedCategory = this.selectedCategory.filter(c => c !== category.id)
      } else this.selectedCategory = category.id
    },
  },
}
</script>
