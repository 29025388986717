<template>
  <div
    v-if="loading || error"
    class="fetching-data-spinner text-center d-flex justify-content-center align-items-center"
  >
    <div v-if="loading">
      <b-spinner />
      <div
        v-if="loadingTitle"
        class="mt-1"
        v-text="loadingTitle"
      />
    </div>
    <div v-else>
      <feather-icon
        size="36"
        icon="AlertCircleIcon"
      />
      <div
        class="mt-1"
      >
        {{ errorMessage }}
        <span
          v-if="enableRetry"
          class="text-primary font-weight-bold cursor-pointer"
          @click="$emit('retry')"
          v-text="$t('errors.retry')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner } from 'bootstrap-vue'
import ErrorHandler from '@/utils/error-handler'

export default {
  name: 'FetchingDataSpinner',
  components: {
    BSpinner,
  },
  props: {
    error: {
      type: [String, Object, Error],
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    enableRetry: {
      type: Boolean,
      default: false,
    },
    loadingTitle: {
      type: String,
      default: '',
    },
  },
  computed: {
    errorMessage() {
      if (this.error instanceof Error) return ErrorHandler.getErrorDetails(this.error)?.title || this.error
      return this.error?.title || this.error?.message || this.error
    },
  },
}
</script>

<style lang="scss">
.fetching-data-spinner {
  min-height: 300px;
}
</style>
