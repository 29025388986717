<template>
  <b-modal
    :visible="value"
    :dialog-class="`ok-modal ${dialogClass}`"
    cancel-variant="secondary"
    centered
    :size="size"
    hide-footer
    hide-header
    hide-header-close
    :no-close-on-backdrop="noCloseOnBackdrop"
    @change="$emit('change', $event)"
  >

    <div class="ok-modal-title">
      <div>
        <slot name="title">
          <span :class="titleClass">{{ title }}</span>
        </slot>
      </div>
      <div>
        <slot name="close">
          <b-button
            class="btn-icon"
            variant="link"
            @click="$emit('change', false)"
          >
            <feather-icon
              icon="XIcon"
              class="text-secondary"
              size="14"
            />
          </b-button>
        </slot>
      </div>
    </div>

    <div class="ok-modal-content">
      <slot />
    </div>

    <div class="ok-modal-footer">
      <slot name="actions" />
    </div>
  </b-modal>
</template>

<script>
import { BModal, BButton } from 'bootstrap-vue'

export default {
  name: 'OkModal',
  components: {
    BModal, BButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    value: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
    },
    dialogClass: {
      type: String,
      default: '',
    },
    titleClass: {
      type: String,
      default: '',
    },
    noCloseOnBackdrop: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
  },
}
</script>

<style lang="scss">
.ok-modal {
  .col-form-label, .checkbox-list {
    font-size: 14px;
  }

  .modal-body {
    padding-bottom: 20px;
  }

  .ok-modal-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: solid 1px #e8e7eb;
    margin-bottom: 13.5px;

    span {
      font-size: 16px;
      font-weight: 600;
      line-height: 1.29;
      color: var(--purple);
    }
  }

  .ok-modal-footer {
    margin-top: 15px;

    .btn {
      font-size: 14px!important;
      font-weight: 600!important;
      line-height: 1.29!important;
    }
  }
}
</style>
