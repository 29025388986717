<template>
  <validation-observer
    ref="form"
    tag="div"
  >
    <div
      v-if="prices.length"
      class="item-prices-form"
    >
      <div class="mt-4 item-prices-list">

        <template v-for="(price, idx) in prices">
          <b-row
            :key="idx"
            class="no-gutters align-items-center"
          >
            <b-col md="3">
              <price-label-select
                :name="`${$t('store.prices.label')} ${idx}`"
                :value="price.label_id"
                @input="price.label_id = $event"
              />
            </b-col>
            <b-col
              md="2"
            >
              <ok-text-input
                v-model="price.price"
                :hide-errors="false"
                :label="$t('store.prices.price')"
                :validation-name="`${$t('store.prices.price')} ${idx}`"
                rules="positive"
              />
            </b-col>
            <b-col
              class="d-flex align-items-center mt-2"
              md="1"
            >
              <b-button
                class="btn-icon rounded-circle price-delete-btn"
                size="sm"
                variant="outline-danger"
                @click="removePrice(idx)"
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
            </b-col>
          </b-row>
          <hr :key="'line-break'+idx">
        </template>
        <div class="mt-1">
          <b-button
            size="sm"
            variant="dark"
            @click="addPrice"
          >
            {{ $t('store.add-another-price') }}
          </b-button>
          <b-button
            class="mx-1"
            size="sm"
            variant="primary"
            @click="showLabelModal=!showLabelModal"
          >
            {{ $t('store.add-another-price-template') }}
          </b-button>
        </div>
      </div>
    </div>
    <div
      v-else
      class="d-flex align-items-center justify-content-center w-100"
      style="min-height: 200px"
    >
      <b-button
        variant="primary"
        @click="addPrice"
      >
        أضف سعر
      </b-button>
    </div>
    <process-label-modal
      v-model="showLabelModal"
      @added="addNewTemplate"
    />
  </validation-observer>
</template>

<script>
import { BButton, BCol, BRow } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import PriceLabelSelect from '@/views/store/inputs/PriceLabelSelect'
import ProcessLabelModal from '@/views/store/price-labels/config/ProcessLabelModal'

export default {
  name: 'ItemPricesForm',
  components: {
    ProcessLabelModal,
    OkTextInput,
    PriceLabelSelect,
    ValidationObserver,
    BCol,
    BRow,
    BButton,
  },
  props: {
    priceLabels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      showLabelModal: false,
      prices: [],
    }
  },
  mounted() {
    this.prices = [...this.priceLabels]
    this.$nextTick(() => {
      this.$forceUpdate()
    })
  },
  methods: {
    prepareForSubmit() {
      return {
        prices: JSON.stringify({ ...this.prices }),
      }
    },
    addPrice() {
      this.prices.push({
        price: 0,
        label_id: this.$store.getters['shop/priceLabels'][0]?.id ?? null,
      })
      this.$forceUpdate()
    },
    removePrice(idx) {
      this.prices.splice(idx, 1)
      this.$forceUpdate()
    },
    async isValid() {
      return this.$refs.form.validate()
    },
    addNewTemplate(template) {
      this.$store.commit('shop/UPDATE_PRICES', template)
    },
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/utils/responsive.scss";

.item-prices-form {
  .item-prices-form-title {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 500;
    color: var(--purple)
  }

  .item-prices-list {
    margin-bottom: 20px;
    padding-bottom: 20px;

    & > .row {
      align-items: center;
      margin-bottom: 10px;

      & > div {
        margin-bottom: 7px;
        padding: 0 10px 0 0;
      }

      @include respond-to('small') {
        border-bottom: unset;
        padding-bottom: 0;

        & > div {
          margin-bottom: 0;
        }
      }
    }
  }

  .add-fist-price-text {
    font-size: 14px;

  }

  .price-delete-btn {
    margin-bottom: 5px;
  }
}

</style>
