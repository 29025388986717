<template>
  <validation-provider
    #default="{ errors }"
    :name="name"
    rules="required"
  >
    <b-form-group
      :label="`${$t('store.prices.label')} (${$t(`locales.${selectedLocale}`)})`"
      :state="errors.length ? false : null"
      label-class="required pt-0"
      :label-cols-lg="labelColsLg"
      class="position-relative"
    >
      <template #label>
        {{ `${$t('store.prices.label')} (${$t(`locales.${selectedLocale}`)})` }}
        <feather-icon
          v-b-tooltip="$t('store.prices.label-hint')"
          icon="AlertOctagonIcon"
          class="cursor-pointer"
        />
      </template>
      <v-select
        :value="value"
        :options="options"
        :reduce="option => option.id"
        label="name"
        :placeholder="`${$t('store.prices.label')} (${$t(`locales.${selectedLocale}`)})`"
        :clearable="false"
        :dir="isRTL ? 'rtl' : 'ltr'"
        :multiple="false"
        :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
        autocomplete="off"
        taggable
        append-to-body
        :calculate-position="withPopper"
        @input="$emit('input', $event)"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </b-form-group>
  </validation-provider>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider } from 'vee-validate'
import { BFormGroup, VBTooltip } from 'bootstrap-vue'
import { createPopper } from '@popperjs/core'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'PriceLabelSelect',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    value: {
      type: [Number, String],
      default: '',
    },
    labelColsLg: {
      type: [Number, String],
      default: 12,
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '',
    },
    exclude: {
      type: Array,
      default: () => ([]),
    },
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    options() {
      const labelOptions = []
      const storedOption = this.$store.getters['shop/priceLabels'] ?? []
      storedOption.forEach(label => {
        labelOptions.push({ id: label.id, name: LocalizationService.getTranslate(label, 'name', this.selectedLocale), type: 'custom' })
      })
      return labelOptions
    },
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      // read more from: https://vue-select.org/guide/positioning.html#popper-js-integration
      // eslint-disable-next-line no-param-reassign
      dropdownList.style.width = width

      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-down',
                state.placement === 'bottom',
              )
            },
          },
        ],
      })

      return () => popper.destroy()
    },
  },
}
</script>

<style lang="scss">
body > ul[role=listbox] {
  z-index: 10000!important;
}
</style>
