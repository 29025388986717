<template>
  <b-form-group
    :label="$t('store.stock-status')"
    :label-cols-lg="12"
  >
    <b-form-select
      :value="value"
      @input="$emit('input', $event)"
    >
      <b-form-select-option
        :value="0"
        v-text="$t('store.available')"
      />
      <b-form-select-option
        :value="1"
        v-text="$t('store.out-of-stock')"
      />
    </b-form-select>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormSelect, BFormSelectOption } from 'bootstrap-vue'

export default {
  name: 'ItemStockStatusInput',
  components: { BFormGroup, BFormSelect, BFormSelectOption },
  props: {
    value: {
      type: [Boolean, Number],
      default: 0,
    },
  },
}
</script>

<style scoped>

</style>
