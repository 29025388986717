<template>
  <div
    class="d-flex justify-content-center align-items-center ok-switch"
    @click="$emit('click')"
  >
    <div
      v-if="!checkbox && (enableLabel || disableLabel)"
      class="mr-1"
    >
      {{ (value ? enableLabel : disableLabel) }}
    </div>
    <b-form-checkbox
      ref="checkbox"
      :disabled="disabled"
      :checked="binary ? (value + 0) : !!value"
      :class="{'light-green-switch': !checkbox, 'mx-0': checkbox}"
      :size="size"
      :value="binary ? 1 : true"
      :unchecked-value="binary ? 0 : false"
      inline
      :switch="!checkbox"
      @change="handleChange"
    />
    <div v-if="checkbox && (enableLabel || disableLabel || label)">
      {{ (value ? enableLabel : disableLabel) || label }}
      <ok-tooltip
        :text="tooltip"
        :images="tooltipImages"
        :custom-class="tooltipClass"
        :placement="tooltipPlacement"
      />
    </div>
  </div>
</template>

<script>
import { BFormCheckbox } from 'bootstrap-vue'
import OkTooltip from '@/@bya3/components/utils/OkTooltip'

export default {
  name: 'OkSwitch',
  components: {
    OkTooltip,
    BFormCheckbox,
  },
  props: {
    value: {
      type: [Boolean, Number],
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    enableLabel: {
      type: String,
      default: '',
    },
    disableLabel: {
      type: String,
      default: '',
    },
    binary: {
      type: Boolean,
      default: true,
    },
    checkbox: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    tooltip: {
      type: String,
      default: '',
    },
    tooltipImages: {
      type: Array,
      default: null,
    },
    tooltipClass: {
      type: String,
      default: '',
    },
    tooltipPlacement: {
      type: String,
      default: null,
    },
  },
  methods: {
    handleChange(checked) {
      this.$emit('input', checked)
      this.$nextTick(() => {
        if (!!this.value !== !!checked && this.$refs?.checkbox) this.$refs.checkbox.computedLocalChecked = this.value
      })
    },
  },
}
</script>
