const CommonTableActions = [
  {
    // label: 'forms.edit',
    eventName: 'edit',
    icon: 'Edit2Icon',
    variant: 'warning',
  },
  {
    // label: 'forms.delete',
    eventName: 'delete',
    icon: 'Trash2Icon',
    variant: 'danger',
  },
]

export default CommonTableActions
