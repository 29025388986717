<template>
  <v-select
    v-show="options.length"
    ref="select"
    class="item-modifiers-select"
    :value="value"
    :options="options"
    :reduce="option => option.id"
    label="name"
    :placeholder="$t('modifiers.modifiers-select-placeholder')"
    :clearable="true"
    :multiple="true"
    :dir="isRTL ? 'rtl' : 'ltr'"
    :loading="loading"
    :class="{'select-size-lg': size === 'lg', 'select-size-sm': size === 'sm'}"
    autocomplete="off"
    @input="$emit('input', $event)"
  >
    <template #list-footer>
      <li>
        <b-button
          v-ripple.400="'rgba(236, 174, 33, 0.15)'"
          variant="link"
          block
          squared
          size="sm"
          class="text-left add-new-group-button"
          @click="handleEmitNewGroup"
        >
          {{ $t('modifiers.add-new-group') }}
        </b-button>
      </li>
    </template>
  </v-select>
</template>

<script>
import vSelect from 'vue-select'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ModifierAPIs from '@/api/store/modifier'
import LocalizationService from '@/services/localization-service'

export default {
  name: 'ItemModifiersSelect',
  components: {
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    size: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      options: [],
      loading: false,
    }
  },
  watch: {
    branches() {
      this.getModifiers()
    },
  },
  mounted() {
    this.getModifiers()
  },
  methods: {
    getModifiers() {
      this.loading = true
      ModifierAPIs.listGroups()
        .then(groups => {
          groups.forEach(mod => this.options.push({ id: mod.id, name: this.getName(mod) }))
          this.$emit('update:groups', groups)
        })
        .catch(e => this.$notifyError(e))
        .finally(() => { this.loading = false })
    },
    addGroup(group) {
      this.options.push({
        ...group, name: this.getName(group), min: 1, max: 5, select_type: 0, optional: 1,
      })
      this.$emit('update:groups', this.options)
    },
    handleEmitNewGroup() {
      this.$emit('add-group')
      setTimeout(() => this.$refs.select.closeSearchOptions(), 200)
    },
    getName(option) {
      return LocalizationService.getTranslate(option, 'name', this.selectedLocale)
    },
  },
}
</script>

<style lang="scss">
.item-modifiers-select {
  ul {
    overflow-x: hidden;
  }

  .add-new-group-button {
    font-size: 13px!important;
    padding: 8px 20px!important;
  }
}
</style>
