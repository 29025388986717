<template>
  <div class="position-relative">
    <div
      v-if="!file || !process"
      class="import-excel-input"
    >
      <vue-dropzone
        id="import-input-dropzone"
        ref="dropzone"
        class="h-100"
        :style="{ minHeight: '100px' }"
        :options="dropzoneOptions"
        :use-custom-slot="true"
        @vdropzone-file-added="handleFileAdded"
      >
        <div class="dropzone-custom-content">
          <feather-icon
            icon="FileIcon"
          />
          <div v-html="$t('excel.import-drop')" />
        </div>
      </vue-dropzone>
    </div>
    <div
      v-else
      class="excel-downloaded"
    >
      <div class="preview-file">
        <FeatherIcon icon="FileIcon" />
        <div class="preview-text">
          <p>{{ $t('excel.uploaded-file') }}</p>
          <p class="font-weight-bold">
            {{ type+'-template.xlsx' }}
          </p>
          <p>{{ $t('excel.excel-items-count',{count:excelData.length,model:$t('excel.'+type)}) }}</p>
        </div>
      </div>
      <div
        class="remove-icon"
        @click="handleFileRemove"
      >
        <FeatherIcon icon="Trash2Icon" />
      </div>
    </div>
  </div>
</template>

<script>
import vueDropzone from 'vue2-dropzone'
import XLSX from 'xlsx'

export default {
  name: 'ImportExcelInput',
  components: {
    vueDropzone,
  },
  props: {
    value: {
      type: [String, Object, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'categories',
    },
  },
  data() {
    return {
      process: false,
      file: null,
      dropzoneOptions: {
        url: '/items/import',
        acceptedFiles: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        autoProcessQueue: false,
        thumbnailHeight: 100,
        maxFilesize: 20,
        maxFiles: 1,
        createImageThumbnails: false,
      },
      excelData: [],
    }
  },
  computed: {
    forMenu() {
      return ['items', 'categories'].includes(this.type)
    },
  },
  watch: {
    excelData(val) {
      const isValid = (val[0][`name_${this.$i18n.locale}`] ?? '') !== ''
      if (val?.length > 0 && isValid) {
        this.process = true
        this.handleImport()
        this.$emit('error', null)
      } else {
        this.process = false
        this.$emit('error', { title: this.$t('excel.import-failed'), message: this.$t('excel.excel-file-empty') })
        try {
          this.$refs.dropzone.removeAllFiles(true)
        } catch (e) { this.process = false }
      }
    },
  },
  methods: {
    async handleFileAdded(file) {
      if (file.manuallyAdded) {
        return
      }
      const files = this.$refs.dropzone?.getAcceptedFiles ? this.$refs.dropzone.getAcceptedFiles() : []
      if (this.maxFiles === files.length && files.length >= 1) {
        this.$refs.dropzone.removeFile(files[0])
      }
      const blob = new Blob([file], { type: file.type })
      this.file = blob
      this.excelExport()
    },
    uploadProgressHandler(progress) {
      this.uploadProgress = progress
    },
    handleFileRemove() {
      this.file = null
      this.$emit('imported', null)
      this.$emit('error', null)
    },
    manuallyAddFile(url, name, size) {
      const file = {
        size, name, accepted: true, status: 'queued',
      }
      this.$refs.dropzone.manuallyAddFile(file, url)
    },
    async handleImport() {
      const body = new FormData()
      body.append('file', this.file, 'import.xlsx')
      this.$emit('imported', body)
    },
    excelExport() {
      const reader = new FileReader()
      reader.onload = () => {
        const fileData = reader.result
        const wb = XLSX.read(fileData, { type: 'binary' })
        wb.SheetNames.forEach(sheetName => {
          this.excelData = XLSX.utils.sheet_to_json(wb.Sheets[sheetName])
        })
      }
      reader.readAsBinaryString(this.file)
    },
  },
}
</script>

<style lang="scss">
.import-excel-input {
  display: flex;
  justify-content: center;
  align-items: center;
  #import-input-dropzone{
    height: 112px;
    padding: 24px 153px 24px 154px;
    border-radius: 5px;
    border: dashed 1px #aaa8b2 !important;
    background-color: #f0eff5 !important;
  }
  .dz-message{
    max-width: 146px;
    svg{
      width: 18px;
      height: 22px;
      margin: 0 64px 10px;
      color: #aaa8b2;
    }
  }
  &.uploading {
    .vue-dropzone {
      opacity: 0.5;
    }
  }

  .vue-dropzone {
    display: flex;
    justify-content: center;
    align-items: center;

    max-height: 111px;
    width: 100%;
    overflow: hidden;
    padding: 0!important;

    .dz-progress {
      display: none!important;
    }

    .dropzone {
      padding: 0;
    }

    .img-thumbnail {
      height: 111px!important;
      width: 111px!important;
      object-fit: contain;
    }

    .dz-preview {
      margin: 0!important;
    }
  }

  .dropzone .dz-preview-cover.dz-image-preview {
    min-height: 111px!important;
    width: auto;
  }

  .dark-layout & {
    .vue-dropzone {
      border: 1px dashed var(--theme-dark-input-border-color) !important;
      background-color: var(--theme-dark-input-bg) !important;
    }

    .img-thumbnail {
      background-color: var(--theme-dark-body-bg);
    }
  }

}
</style>
