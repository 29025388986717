<template>
  <ok-modal
    :title=" $t('price.quick-edit-price')"
    :value="value"
    dialog-class="list-modal"
    size="lg"
    @change="loading ? null : $emit('input', $event)"
  >
    <validation-observer ref="form">
      <error-alert :error="error" />
      <slide-y-up-transition>
        <b-row
          class="discount mt-3"
        >
          <b-col
            md="12"
            sm="12"
          >
            <ok-text-input
              v-model="form.edit_value"
              :is-required="true"
              :label="$t('price.edit-value')"
              :rules="discountRule"
              :show-append="true"
            >
              <template #append>
                <b-form-select
                  v-model.trim="form.edit_type"
                  style="border-radius: 0"
                >
                  <b-form-select-option
                    value="%"
                    v-text="'%'"
                  />
                  <b-form-select-option
                    value="fixed"
                    v-text="currency"
                  />
                </b-form-select>
              </template>
            </ok-text-input>
          </b-col>
          <b-col
            md="12"
            sm="12"
          >
            <h4 class="font-weight-bold my-2">
              {{ $t('price.edit-type') }}
            </h4>
            <b-form-radio-group
              v-model="form.increase"
              :options="increaseOptions"
              class="ml-3"
              text-field="text"
              value-field="value"
            />
          </b-col>
          <b-col
            md="12"
            sm="12"
          >
            <h4 class="font-weight-bold my-2">
              {{ $t('price.with-round') }}
            </h4>
            <b-form-radio-group
              v-model="form.round"
              :options="roundOptions"
              class="ml-3"
              text-field="text"
              value-field="value"
            />
          </b-col>
          <b-col
            md="12"
            sm="12"
          >
            <h4 class="font-weight-bold my-2">
              {{ $t('price.applied-to') }}
            </h4>
            <b-form-radio-group
              v-model="appliedTo"
              :options="options"
              class="ml-3"
              text-field="text"
              value-field="value"
            />
          </b-col>
          <b-col
            class="mt-1"
            md="6"
            sm="12"
          >
            <ok-text-input
              v-if="appliedTo==='categories'"
              :label="$t('discounts.choose-category')"
              :name="$t('discounts.categories')"
              is-required
            >
              <category-select
                v-model="form.categories"
                :multiple="true"
                :selected="categoriesInitSelected"
                fire-object
                @categories="tableItems=$event"
              />
            </ok-text-input>

            <ok-text-input
              v-if="appliedTo==='items'"
              :label="$t('discounts.choose-item')"
              :name="$t('discounts.items')"
              is-required
            >
              <items-select
                v-model="form.items"
                :multiple="true"
                :selected="itemsInitSelected"
                fire-object
                @items="tableItems=$event"
              />
            </ok-text-input>
          </b-col>
          <b-col
            v-if="tableItems.length"
            md="12"
          >
            <b-table
              :fields="fields"
              :items="tableItems"
              bordered
              borderless
              striped
            >
              <template #cell(name)="data">
                {{ getName(data.item) }}
              </template>
              <template #cell(action)="data">
                <b-button
                  size="sm"
                  variant="danger"
                  @click="removeItem(data.item.id)"
                >
                  {{ $t('forms.delete') }}
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </slide-y-up-transition>
    </validation-observer>

    <template #actions>
      <div class="w-100 d-flex">
        <b-button
          :disabled="loading"

          variant="dark"
          @click="submit"
        >
          <b-spinner
            v-if="loading"
            class="mr-50"
            small
          />
          {{ $t('forms.submit') }}
        </b-button>
        <b-button
          class="mx-1"
          variant="outline-dark"
          @click="$emit('close')"
        >
          {{ $t('forms.cancel') }}
        </b-button>

      </div>
    </template>
  </ok-modal>

</template>

<script>
import {
  BButton, BCol, BFormRadioGroup, BFormSelect, BFormSelectOption, BRow, BTable,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import OkTextInput from '@/@bya3/components/inputs/OkTextInput'
import ErrorAlert from '@/@bya3/components/alerts/ErrorAlert'
import CategorySelect from '@/@bya3/components/inputs/CategorySelect'
import LocalizationService from '@/services/localization-service'
import ItemsSelect from '@/views/store/products/components/ItemsSelect'
import ErrorHandler from '@/utils/error-handler'
import OkModal from '@/@bya3/components/modals/OkModal'
import PriceLabelsAPIs from '@/api/store/price-label'

export default {
  name: 'QuickEditPriceForm',
  components: {
    OkModal,
    ErrorAlert,
    OkTextInput,
    BFormSelectOption,
    BFormSelect,
    BRow,
    BCol,
    BTable,
    BButton,
    ValidationObserver,
    BFormRadioGroup,
    ItemsSelect,
    CategorySelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      required,
      loading: false,
      error: null,
      form: {},
      options: [],
      appliedTo: 'all_menu',
      itemsInitSelected: [],
      categoriesInitSelected: [],
      fields: [
        { key: 'name', label: this.$t('fields.name') },
        { key: 'action', label: this.$t('forms.actions') },
      ],
      tableItems: [],
      increaseOptions: [],
      roundOptions: [],
    }
  },
  computed: {
    currency() {
      return this.$store.getters['auth/currency'] ?? ''
    },
    discountRule() {
      return (this.form?.edit_type === '%') ? 'positive|min_value:0.01|max_value:100' : 'positive|min_value:0.01'
    },
  },
  mounted() {
    this.form = this.getInitialForm()
    this.options = [
      { text: this.$t('discounts.all-menu'), value: 'all_menu' },
      { text: this.$t('discounts.specific-categories'), value: 'categories' },
      { text: this.$t('discounts.specific-items'), value: 'items' },
    ]
    this.increaseOptions = [
      { text: this.$t('price.increase'), value: 1 },
      { text: this.$t('price.decrease'), value: 0 },
    ]
    this.roundOptions = [
      { text: this.$t('general.yes'), value: 1 },
      { text: this.$t('general.no'), value: 0 },
    ]
  },
  methods: {
    getName(item) {
      if (item?.name) return item.name
      return LocalizationService.getTranslate(item, 'name', this.selectedLocale)
    },
    removeItem(id) {
      let index = -1
      if (this.appliedTo === 'items') {
        index = this.form.items.findIndex(item => item === id)
        if (index > -1) this.form.items.splice(index, 1)
      } else {
        index = this.form.categories.findIndex(item => item === id)
        if (index > -1) this.form.categories.splice(index, 1)
      }
    },
    getInitialForm() {
      return {
        increase: 1,
        round: 0,
        edit_value: 0,
        edit_type: '%',
        all_menu: false,
        categories: [],
        items: [],
      }
    },
    async submit() {
      this.error = null
      const valid = await (this.$refs.form.validateWithInfo())
      if (!valid.isValid) {
        this.$emit('error')
        this.error = {
          title: this.$tc('errors.field-invalid', 2),
          message: ErrorHandler.getValidationErrors(valid.errors),
        }
        return
      }
      let msg = ''
      const isPercent = this.form.edit_type === '%'
      const val = `${this.form.edit_value}${isPercent ? '' : this.currency}`
      if (this.form.increase) {
        msg = this.$t(`price.edit-value-increase-${isPercent ? 'percent' : 'fixed'}`, [val])
      } else {
        msg = this.$t(`price.edit-value-decrease-${isPercent ? 'percent' : 'fixed'}`, [val])
      }
      this.$okConfirm(msg, {
        confirmButtonText: this.$t('forms.submit'),
        showCancel: true,
        preConfirm: async () => {
          const form = { ...this.form }
          form.all_menu = this.appliedTo === 'all_menu' ? 1 : 0
          if (this.appliedTo === 'all_menu') {
            form.categories = []
            form.items = []
          } else if (this.appliedTo === 'categories') {
            form.items = []
          } else if (this.appliedTo === 'items') {
            form.categories = []
          }
          if (this.form?.id) form.id = this.form.id
          await PriceLabelsAPIs.editBulk(form)
            .then(res => {
              if (res.error === 0) {
                this.$notifySuccess(res.message)
                this.form = { ...this.getInitialForm() }
                this.$emit('input', false)
                this.$emit('updated', true)
              } else {
                this.$notifyError(res.message)
              }
            })
            .catch(e => {
              this.error = e
              this.$notifyError(e)
            })
        },
      })
    },
  },
}
</script>
